* {
  font-family: "Ubuntu", sans-serif !important;
}

.fontHeader {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 700 !important;
  letter-spacing: 1px;
  font-size: 22px !important;
  text-align: center;
  margin-top: 0;
  margin-bottom: "10px";
}

.fontSectionHeader {
  font-size: 18px !important;
  letter-spacing: 4px;
  font-weight: 300 !important;
}

/* disabled setting a color for all the links for now */
/* a { */
/* color: red !important; */
/* } */

.colorHighlight {
  color: #4183c4 !important;
}

.colorDim,
.colorSectionItem,
.colorSkillTitle {
  color: #404040 !important;
}

.colorHeart {
  color: #da0037 !important;
}

.textShadowCorner {
  text-shadow: 1.5px 1.5px 0px rgb(0 0 0 / 20%);
}

.px16 {
  font-size: 16px !important;
}

.px14 {
  font-size: 14px !important;
}

.px12 {
  font-size: 12px !important;
}

.px10 {
  font-size: 10px !important;
}

div#narrowSkillTitle {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

@media print {
  .noPageBreak {
    page-break-inside: avoid;
  }

  .hideFromPrint {
    display: none;
  }

  body {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 700px !important;
    /* padding-top: 0.19in; */
    /* Manually set 0.19 top padding via the print pop-up window */
  }
}

@page {
  size: A4;
}
